@import "../../../assets/styles/views/web/webVariables.less";

//footer section start
.footer-section {
    padding: 0.4rem;
    border-top: 1px solid rgba(23, 23, 23, 0.50);
    box-shadow: 4px 0px 10px 0px rgba(23, 23, 23, 0.15);
    position: relative;
    z-index: 5;
    background-color: #fff;
    margin-top: 5rem;    

    .footer-setion-terms {
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;


            li {

                padding-right: 3rem;
                position: relative;
                margin-right: 3rem;
                font-size: 1.6rem;
                font-weight: @font-weight-400;
                text-transform: uppercase;
                color: @black_color;
                transition: all 0.5s ease-in-out;
                font-family: @primary-font-family;

                .active {
                    color: @green_color;
                }

                a {

                    &:hover {
                        color: @green_color;
                        cursor: pointer;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    height: 30px;
                    width: 1px;
                    background-color: @green_color;
                    right: 0;
                    top: -0.5rem;
                }

                &:last-child {
                    margin-right: 0;
                    padding-right: 0;

                    &::after {
                        display: none;
                    }
                }
            }

            @media (max-width:@tablet-width) {
                justify-content: center;
                margin: 2rem 0;
            }

            @media (max-width:@mobile) {
                li {
                    text-align: center;
                    font-size: 1.4rem;
                    padding-right: 1rem;

                    margin-right: 1rem;
                }
            }
        }
    }
    .footer-setion-copy {
        font-size: 1.6rem;

        @media (max-width:@tablet-width) {
            text-align: center;
        }

        @media (max-width:@mobile) {
            font-size: 1.4rem;
            margin: 10px 0;

        }

    }
    @media (max-width:@min-mobile) {
        .footer-section-row{
            flex-direction: column;
        }
    }
}

//footer section end