// .ant-table {
//   border: 1px solid #f0f0f0;
//   table {
//     box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
//     .ant-table-thead > tr > th {
//       .themeColorOpacity(@primary-color,0.1);
//     }
//     .ant-table-thead > tr > th,
//     .ant-table-tbody > tr > td,
//     .ant-table tfoot > tr > th,
//     .ant-table tfoot > tr > td {
//       padding: 10px 15px;
//     }
//     .ant-table-tbody > tr {
//       > td {
//         border-right: 1px solid #f0f0f0;
//         &:last-child {
//           border-right: 0;
//         }
//       }
//       &:last-child {
//         > td {
//           border-bottom: 0;
//         }
//       }
//     }
//   }
// }

.ant-table {
  background-color: #fff;
  border: 0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);

  .ant-table-thead>tr>th {
    color: @primary-color;
    background: #ebebeb;
  }

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 12px 16px;
  }

  .ant-table-title {
    font-weight: 600;
    padding: 0 6px 12px;
  }
}

.adminDropdown {
  min-width: 220px;

  .ant-form-item {
    margin: 0;
  }
}

#map {
  height: 100%;
}

.addModerator {
  .addBtn {
    border: 1px solid @secondary-color;
    color: @secondary-color;
    font-weight: 500;
    border-radius: 5px;
  }

  .anticon-minus-circle {
    font-size: 20px;
    margin-left: 10px;
  }
}

.mapModal {
  #map {
    height: calc(85vh - 220px);
  }
}

.colorBoxCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .gridColorBox {
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }
}

.imgBoxCell {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-image {
    width: 30px;
    height: 30px;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      vertical-align: top;
    }

    .ant-image-mask-info {
      font-size: 0;
      height: 30px;

      .anticon {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}

.pagenotfound__section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .notFoundText {
    font-size: 20px;
  }
}

.accessDeniedPage {
  .content {
    text-align: center;

    h1 {
      font-size: 38px;
      font-weight: 600;
      color: @primary-color;
    }

    h2 {
      font-size: 26px;
      margin: 0;
    }

    p {
      font-size: 18px;
    }
  }

  @media (max-width: 1600px) {
    .content {
      h1 {
        font-size: 34px;
        margin: 0;
      }

      h2 {
        font-size: 22px;
      }

      p {
        font-size: 16px;
        margin: 0;
      }
    }

    .notFoundImg img {
      max-height: 340px;
    }
  }
}

.grayTag {
  padding: 5px 8px;
  background-color: #f5f5f5;
  margin: 0 2px 4px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
}

.osTag {
  padding: 5px 8px;
  background-color: #e5e5e5;
  cursor: pointer;
  .themeColorOpacity(@primary-color, 0.1);
  margin: 0 2px;
  border-radius: 5px;
  border: 1px solid @primary-color;
  line-height: normal;

  svg {
    margin-left: 6px;
    fill: #fff;
    color: @primary-color;
  }
}

.osTagModal .osTag {
  display: inline-block;
  margin: 6px 6px;
}

.DutiesTable {
  border: 1px solid #f0f0f0;

  .DutiesTableRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: 0;
    }

    .DutiesTableCol {
      border-right: 1px solid #f0f0f0;
      padding: 10px 15px;

      &.head {
        width: 30%;
        font-weight: 500;
      }

      &:nth-child(2) {
        width: 70%;
      }

      &:last-child {
        border-right: 0;
      }

      .tag {
        padding: 5px 8px;
        background-color: #f1f1f1;
        margin: 2px;
        border-radius: 5px;
        display: inline-block;
      }
    }
  }
}

.userSelectOption {
  display: flex;
  justify-content: space-between;
}

.formInfo {
  width: 100%;

  .formInfoWrap {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: 0;
    }

    .formInfoHead {
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      color: #7c7c7c;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .formInfoContent {
      font-size: 16px;
      font-weight: 500;
      color: #141414;
      word-break: break-all;
    }
  }
}

.divAnchor {
  color: @primary-color;
  text-decoration: underline;
  cursor: pointer;
}

.ant-descriptions-item-label {
  font-weight: 600;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 10px 15px;
}

table.customTable {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  width: 100%;
  border: 1px solid #f0f0f0;

  th {
    background-color: rgba(0, 143, 213, 0.1);
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  th,
  td {
    padding: 10px 15px;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
}

table.marriageTable {
  width: 100%;
  border: 1px solid #f0f0f0;

  th {
    background-color: rgba(0, 143, 213, 0.1);
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }

  th,
  td {
    text-align: center;
    padding: 10px 15px;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;

    &:first-child {
      font-weight: 500;
      background-color: #fbfbfb;
      text-align: left;
    }

    .avatar {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }
}

.searchAROMobile {
  input.ant-input {
    height: 35px;
    font-size: 14px;

    &.ant-input-status-error {
      border-color: #d0d0d0;

      &:not(.ant-input-disabled):not(.ant-input-borderless) {
        border-color: #d0d0d0;
        box-shadow: none;
      }
    }
  }

  .secondarySmallBtn {
    border-radius: 5px;
    color: @white;

    &:hover {
      background-color: @primary-color;
    }
  }
}

.jsonMakeWrap {
  .iconBtn {
    position: absolute;
    top: 35px;
    right: 20px;
    background-color: transparent;
    min-width: 1px;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 0;

    &.orangeBorder {
      border: 1px solid @orange-btn;
    }

    &.blueBorder {
      border: 1px solid @blue-btn;
    }
  }

  textarea {
    padding-right: 42px;
  }
}

.topDateFilterWrap {
  background-color: @white;
  border-radius: 8px;
  padding: 20px 20px 0;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
}

.pilotTopFilterWrap {
  background-color: @white;
  border-radius: 8px;
  padding: 0;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  border: 0;
  overflow: hidden;

  .ant-collapse-item {
    border: 0;

    .ant-collapse-header {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.attendancePunch {
  label {
    display: flex;
    align-items: center;
    color: @text_dark_black;
    font-weight: 600;
    margin-bottom: 10px;

    .icon {
      width: 37px;
      height: 37px;
      margin-right: 10px;
      background-color: #f4f5f7;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: @primary-color;
      }
    }
  }

  .singleData {
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    min-height: 40px;
  }

  .punchBox {
    min-height: 40px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;

    .punchLeft {
      display: flex;
      align-items: center;
      background-color: @input-bg;
      padding: 5px 10px;
      width: 94px;
      justify-content: center;
      border-radius: 8px;
      margin-right: -10px;
      z-index: 1;
    }

    .punchRight {
      width: calc(100% - 94px);
      border: 1px solid #e7e7e7;
      border-radius: 8px;
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      padding: 5px 10px 5px 15px;

      .ant-image img {
        max-width: 50px;
        max-height: 50px;
      }
    }
  }
}

.backColorSelection {
  position: relative;

  .backColorPicker {
    position: absolute;
    bottom: 0px;
    left: 6px;
    margin: 0;
  }

  input[type="text"] {
    text-align: right;
  }
}

.inactiveTabWrap {
  background-color: @white;
  border-radius: 8px;
  overflow: hidden;
  border: 0;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);

  .iconBtn {
    width: 35px;
    height: 35px;
  }

  .ant-tabs-nav {
    padding: 5px 10px 0 20px;

    .ant-tabs-tab {
      border: 0;
      font-size: 14px;
      color: @text_dark_black;

      .ant-tabs-tab-btn {
        position: relative;

        &:before {
          content: "";
          width: 2px;
          height: 20px;
          display: block;
          background-color: #f0f0f0;
          position: absolute;
          top: 3px;
          left: -20px;
        }
      }

      &:first-child {
        .ant-tabs-tab-btn {
          &:before {
            display: none;
          }
        }
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: @text_dark_black;
        }
      }

      +.ant-tabs-tab {
        margin-left: 40px;
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 20px;

    .ant-table {
      border-radius: 8px;
      overflow: hidden;

      table {
        .ant-table-thead>tr>th {
          background-color: @primary-color;
          color: @white;
          border: 0;
          font-weight: 600;
          text-align: right;
          padding: 12px 16px;

          &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            display: none;
          }

          &:first-child {
            width: 80%;
            text-align: left;
          }

          &:last-child {
            width: 20%;
          }
        }

        .ant-table-tbody>tr>td {
          background-color: #fbfbfb;
          border-right: 0;
          font-weight: 500;
          text-align: right;
          padding: 12px 16px;

          &:first-child {
            width: 80%;
            text-align: left;
          }

          &:last-child {
            width: 20%;
          }
        }

        .ant-table-row-indent+.ant-table-row-expand-icon {
          background: transparent;
          box-shadow: none;
          color: @primary-color;
          border: 0;
          width: 18px;
          height: 18px;
          line-height: 18px;

          &::before {
            top: 9px;
            right: 4px;
            left: 1px;
            height: 1px;
          }

          &::after {
            top: 4px;
          }
        }
      }
    }
  }
}

.piChartWrap {
  background-color: @white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  max-height: 600px;
}

.fullWidthCheckbox .ant-checkbox-wrapper {
  width: 100%;
  margin: 0 0 6px;
}

.errorBoundry {
  border: 1px solid @error-color;
  .themeColorOpacity(@error-color, 0.1);
  color: #141414;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  max-height: 250px;
  overflow-y: auto;

  .errorKey {
    margin-top: 5px;
    font-weight: 500;
  }
}

.SLAPageWrap {
  .innerRowBackground {
    border-radius: 8px;
    padding: 10px;
    background-color: #ebeff3;
    margin-bottom: 20px;
  }

  .matchRuleWrap {
    border-radius: 8px;
    padding: 10px;
    background-color: #ebeff3;
    margin-bottom: 20px;

    .ant-row:last-child .ant-col .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ruleAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 14px;

    .anticon {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}

.SLAGrid {
  .ant-card {
    margin: 20px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);

    .ant-card-head {
      min-height: 38px;
      background-color: #f5f5f5;

      .ant-card-head-title {
        padding: 6px 0;
      }
    }

    .ant-card-type-inner {
      margin: 0;
      box-shadow: none;

      +.ant-card-type-inner {
        margin-top: 15px;
      }

      .ant-card-head {
        background-color: #fbfbfb;
      }

      .ant-card-body {
        max-height: none;
      }
    }

    .ant-descriptions-row>th,
    .ant-descriptions-row>td {
      padding-bottom: 5px;

      .ant-descriptions-item-content {
        flex-wrap: wrap;
      }
    }

    .ant-card-body {
      padding: 14px;
      overflow-y: auto;
      max-height: 300px;
    }
  }

  .ag-full-width-container [class*="ag-row-level-"] {
    overflow-y: auto;
    display: flex;
    background-color: #fbfbfb !important;
  }

  // .ag-full-width-container .ag-row {
  // }
}

.createIssuePageWrap {
  h2 {
    margin-bottom: 20px;
  }

  .app__container {
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
    background-color: @white;
    border-radius: 8px;
    padding: 20px;
  }

  .issueDetail {
    border: 1px solid #c5c5c5;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 30px;

    .innerRow {
      background-color: #ebeff3;
      padding: 20px 20px 0 !important;
      margin-bottom: 20px;
      border-radius: 8px;
      display: inline-block;
      width: 100%;
    }

    .removeBtn {
      border: 1px solid @error-color;
      color: @error-color;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
}

.withoutLoginIssueWrap {
  .site-layout {
    padding: 0 20px;
  }

  header {
    background-color: @white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    position: sticky;
    top: 0;
    z-index: 9;
    border-top: 5px solid @primary-color;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);

    .leftPart {
      display: flex;
      align-items: center;

      img {
        max-width: 130px;
        padding-right: 15px;
        border-right: 1px solid #ddd;
      }

      .header_title {
        margin-left: 15px;
        text-transform: uppercase;
        word-spacing: 2px;
        max-width: 160px;
        font-size: 18px;
        line-height: 25px;
        line-height: normal;
      }
    }

    .social_media ul {
      display: flex;
      align-items: center;

      li {
        line-height: normal;
        width: 40px;
        height: 40px;
        border: 1px solid #ddd;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 15px;
        cursor: pointer;
        transition: all 0.9s ease;

        svg {
          transition: all 0.9s ease;
        }

        &.facebook {
          &:hover {
            border: 1px solid transparent;
            background-color: #3b5b95;

            svg {
              fill: #fff;
            }
          }
        }

        &.twitter {
          &:hover {
            border: 1px solid transparent;
            background-color: #56aeeb;

            svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  h2 {
    text-align: center;
    margin: 20px 0;
  }

  .withoutLoginIssueForm {
    max-width: 940px;
    background-color: @white;
    padding: 20px;
    border-radius: 8px;
    margin: 30px auto;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);

    .innerSec {
      border: 1px solid #c5c5c5;
      border-radius: 8px;
      padding: 15px;
      margin: 12px 0 30px;
    }
  }

  @media (max-width: 1600px) {
    header {
      height: 120px;

      .leftPart {
        img {
          max-width: 110px;
        }

        .header_title {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

.pollutionDashDesc {
  &.ant-descriptions-small .ant-descriptions-row {

    >th,
    >td {
      padding-bottom: 0;
      padding-right: 12px;
    }
  }
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 8px;
}

.unauthoriseIssueDetail {
  padding: 30px;

  .heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: @primary-color;
    font-weight: 500;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 20px;

    span {
      font-size: 12px;
      margin-left: 5px;
      color: #818181;
    }
  }

  .complaintBlock {
    background-color: @white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
    margin-bottom: 20px;

    ul li {
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      border-bottom: 1px solid #e7e7e7;

      &:last-child {
        border-bottom: 0;
      }

      .head {
        color: #adabab;
      }
    }
  }

  .ant-tabs {
    .ant-tabs-nav {
      margin: 0;

      .ant-tabs-tab {
        background-color: @primary-color;
        color: #fff;
        border-radius: 8px !important;
        margin-bottom: 4px;
        padding: 5px 16px;
        border: 0;

        +.ant-tabs-tab {
          margin-left: 7px;
        }
      }

      .ant-tabs-tab-active {
        background-color: #fff;
        border-radius: 8px 8px 0 0 !important;
        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
        margin: 0;
        padding: 8px 16px;
      }

      .ant-tabs-nav-list {
        padding-top: 3px;
      }
    }

    .ant-tabs-content-holder {
      background-color: #fff;
      border-radius: 0 8px 8px 8px;
      padding: 20px;
      box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
    }
  }
}

.startExamModal {
  .instructionTitle {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .skillRound {
    height: 2rem;
    width: 2rem;
    border: 0.2rem solid @green_bg_color;
    border-radius: 2rem;
  }

  .instructionEmoji {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    svg {
      height: 4.2rem;
      width: 4.2rem;
    }

    .instructionEmojiContent {
      margin: 0;
    }
  }

  .ant-modal-close {
    svg {
      height: auto;
    }
  }

  .ant-modal-body {
    p {
      margin: 0 0 2rem 0;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }

  .examStartModal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.feedBack {
    margin: 0 0 2rem 0;
    text-align: center;
  p {
    margin: 0 !important ;
    color: @black_color;
  }
  .link{
   a {
    color: @green_color;
    &:hover {
      color: @logo_blue_color;
      text-decoration: underline;
    }
    }
  }  
}
