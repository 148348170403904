.LogInRegister {
    border-bottom: 0.5px solid rgba(23, 23, 23, 0.50);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

    .logInRegisterBtnLogo{
        padding: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        cursor: pointer;
        height: 100px;
        width: 100px;
    }
}