.main__page__appheader {
  display: flex;
  align-items: center;
  padding-inline: inherit !important;
  padding: 15px @gutter !important;
  line-height: normal;
  background-color: @layout-body-background !important;
  border-bottom: 1px solid #e8e8e8;
  height: auto !important;

  // box-shadow: 0px 2px 10px -6px rgb(0 0 0 / 75%);
  // box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  .headerWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width:@min-mobile) {
      gap: 2rem;
    }
  }

  .appheader__right {
    margin-left: auto;

    .userDropdown {
      padding: 1rem 1.5rem;
      border-radius: 50px;
      background: #FFF;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

      .userMenuWrap {
        display: flex;
        align-items: center;
        cursor: pointer;

        >span {
          margin-right: 10px;
        }

        .avatar img {
          width: 40px;
          height: 40px;
          background-color: transparent;
          border-radius: 8px;
        }

        .userName {
          font-weight: 400;
          font-size: 1.8rem;
        }

        .anticon {
          font-size: 12px;
        }
      }
    }
  }

  .appheader__left {
    display: flex;
    align-items: center;

    .menuToggle {
      .icon {
        width: 42px;
        height: 42px;
        background-color: @white;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        border-radius: 8px;
        z-index: 999;
        cursor: pointer;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

        span {
          background-color: @text_dark_black;
          height: 2px;
          position: absolute;
          left: 11px;
          transition: all 0.5s ease;

          &:first-child {
            width: 20px;
            top: 14px;
          }

          &:nth-child(2) {
            width: 15px;
            top: 20px;
          }

          &:last-child {
            width: 10px;
            top: 26px;
          }
        }

        &.right span {
          right: 11px;
          left: auto;
        }
      }
    }

    >div {
      margin-right: 20px;
    }

    .syncMenu button {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 0;
      color: @white;
      background-color: @primary-color;
      border: 1px solid @primary-color;
      font-size: 16px;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: @white;
        }
      }
    }

    .adminDropdown {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
        background-color: @white;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .sidebar__trigger {
    cursor: pointer;
  }

  @media (max-width:@min-mobile) {
    .appheader__right {
      .userDropdown {
        padding: 0.5rem;

        .userMenuWrap {

          .userName {

            font-size: 1.4rem;
          }
          .avatar img {
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }
}

.app_header_left {
  height: 89px;
  width: 89px;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
  }
}