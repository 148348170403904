@import "../../../../assets/styles/views/web/webVariables.less";

.profileLayout {
    padding: 30px;
    max-width: 1050px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid @green_color;
    border-radius: 20px;
    .lableWithCheckBox{
        margin-bottom: 6px;
    }

    .profileBtn {
        text-align: center;
        margin: 3rem 0 0 0;

        .ant-btn {
            margin-right: 2rem;

            &:nth-child(3) {
                margin-right: 0;
            }
        }
    }

    .userContent {
        position: relative;
        margin-bottom: 4rem;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2rem;
            border: 1px solid @green_color;
            height: auto;
            opacity: 0.5;
            width: 100%;
            margin: 0 auto;
        }
    }

    .profileLable {
        color: #181c32;
        font-weight: 400;
        font-family: @primary-font-family !important;
        font-size: 1.6rem;
        min-width: 2px;
    }

    .progress {
        width: calc(100% - 6rem);

        .ant-divider {
            margin: 9px 0;
        }

        .progress_content {
            display: flex;
            align-items: center;
            /* Vertically center the content */
        }

        .progress_title {
            margin: 0;
            font-size: 1.6rem;
            font-weight: @font-weight-300;
            min-width: 15rem;
            flex-grow: 1;
            text-align: left;
            white-space: nowrap;
            /* Prevent title from breaking to the next line */

        }

        .ant-progress-line {
            margin-bottom: 0;
        }

        .ant-progress-bg {
            background-color: @green_color;
        }
    }

    .userProfileImg {
        text-align: center;

        svg {
            height: 90px;
        }
    }

    .userProfileContent {
        display: flex;
        align-items: center;
        gap: 3rem;
    }
    .ant-select-single {
        height: 42px !important;
    }

    @media (max-width:@mobile) {
        padding: 15px;

        .userProfileContent {
            flex-direction: column;
        }

        .progress {
            width: 100%;
        }

        .userContent {
            &::after {
                width: 90%;
            }
        }
    }
}