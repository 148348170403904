* {
  font-family: @primary-font-family ;
  box-sizing: border-box;
  scrollbar-color: #b5b8bb #e9e9e9;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  ::-webkit-scrollbar-thumb {
    background: #b5b8bb;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #93999e;
    }
  }
}

html,
body {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  // font-size: 1rem;
  font-family: @primary-font-family !important;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;

  .webBody {
    width: 100dvw;
    overflow-x: hidden;
  }


}

@keyframes cookies {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }
}

.cookies {
  z-index: 9999;
  border-top: 2px solid @green_color;
  position: fixed;
  width: 100%;
  bottom: -110%;
  padding: 2rem;
  background: @white_color;
  color: @green_color;
  box-shadow: 0px -6px 54px -20px rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(3px);
  transition: 2s all ease-in-out;
  animation: cookies 2s;
  opacity: 0;



  &.active {
    opacity: 1;
    bottom: 0%;
  }

  h2 {
    font-size: 2.4rem;
  }

  a {
    color: @green_color;
    border-bottom: 1px solid @green_color;

    &:hover {
      color: @green_color;
    }
  }

  p {
    font-size: 1.4rem;
  }

  .cookies-content {

    .ant-btn {
      margin: 1rem;

      &:nth-child(3) {
        margin: 0rem 0;
      }

    }
  }

  .closeCookies {

    cursor: pointer;
    position: absolute;
    top: -1rem;
    right: -1rem;
    background: @white_color;
    padding: 0.5rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    border: 2px solid @green_color;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    &:hover {
      color: @white_color;
      background-color: @green_color;
    }
  }

  .cookiesBtn {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
    margin: 0;

    .ant-btn.green_border_common_btn,
    .common_button.green_border_common_btn,
    .ant-btn.common_button.green_border_common_btn {
      height: unset;
      min-height: unset;
      max-height: unset;
      top: 1px;
      bottom: auto;
      line-height: unset;
      padding: 7px 20px;

      &:is(:hover, :focus-within) {
        line-height: unset;
        padding: 7px 20px;
      }
    }
  }
}

// styling for map for multiple locations of career and training center : start
.map-container__map {

  height: calc(100vh - 300px) !important;
}

.vr-map {

  .locationDescription{
    min-width: 300px !important;
    position: relative;
    .locationIcon{
      position: absolute;
      right: 15px;
      cursor: pointer;
    }
  }

  .gm-style-iw-a {
    #greenHeading {
      background-color: @green_bg_color ;
      color: @white_color;
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }

    #redHeading {
      background-color: #ff3131;
      color: @white_color;
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }

    #bodyContent {
      h4 {
        padding: 1rem;
        margin: 0;
      }

      p {
        padding: 1rem;
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.4;
      }
    }

    .gm-style-iw {
      padding: 0 !important;
      min-width: 170px !important;

      .gm-style-iw-chr{
        display: none !important;     
      }
      .gm-style-iw-d {
        overflow: auto !important;
      }

    }

  }

  .gm-ui-hover-effect {
    top: 3px !important;
    right: 15px !important;

    span {
      background-color: @white_color !important;
      width: 17px !important;
      height: 20px !important;
      margin: 6px !important;
    }
  }
}
// styling for map for multiple locations of career and training center : end

.breadcrumb {
  .ant-breadcrumb {
    padding: 0;
    margin: 2rem 0;

    ol {
      align-items: center;

      li {
        &:last-child {
          font-weight: 500;
        }

        span {
          font-family: @primary-font-family;
          font-weight: @font-weight-500;
          font-size: 2rem;
        }
      }
    }

    a,
    li {
      color: @green_color;

      &:hover {
        background-color: transparent;
      }
    }

  }
}

.windBgImg {
  position: relative;
  isolation: isolate;
  min-height: calc(100vh - 280px);
  background-image: url("../../../assets/webimage/windBgImg.jpg");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}

.videoModal,
.welcomeModal {
  svg {
    fill: @green_color;
  }

  .ant-modal {
    .ant-modal-body {
      height: 500px;
      overflow: hidden;
      padding: 1rem;
    }

    .ant-modal-close {
      right: -10px;
      top: -10px;
      height: 30px;
      width: 30px;
      background: @white_color;

      .ant-modal-close-x {
        width: auto;
        height: auto;
      }

      svg {
        fill: @green_color;
      }
    }
  }

  .ant-modal-footer {
    margin: 0;
    padding-top: 5px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.2;
  list-style: none;
  box-sizing: border-box;
  font-size: 1.6rem;
  text-decoration: none;
}

p {
  margin: 1.5rem 0;
  font-size: 1.8rem;
  font-weight: @font-weight-400;
  font-family: @primary-font-family;

  @media (max-width: @tablet-width) {

    font-size: 1.4rem;
  }
}

.container {
  width: 90%;
  max-width: 1627px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.overflowVisible {
  overflow: visible;

  .grayBgSectionImg {
    width: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: @mobile) {
      height: 350px;
      object-fit: cover;
      object-position: center;

    }
  }
}

.subtitle {
  font-family: @secondry-font-family;
  font-size: 5rem;
  font-weight: @font-weight-400;

  @media (max-width: @tablet-width) {
    font-size: 3rem;
  }

  @media (max-width: @mobile) {
    font-size: 2rem;
  }
}


// for heading tiltle and sub title start

.content_section {
  text-align: center;
  padding: 0px 20px;
  margin-bottom: 50px;

  h4 {
    font-size: 3rem;
    font-family: @secondry-font-family;
    font-weight: @font-weight-400;
  }

  @media (max-width:@mobile) {
    margin-bottom: 5rem;

    h4 {
      font-size: 2rem;
    }
  }

  @media (max-width:@min-mobile) {
    // margin-bottom: 1rem;

    h4 {
      font-size: 1.8rem;
    }
  }

}

.heading {
  color: @black_color;
  font-family: @secondry-font-family;
  font-size: 7.6rem;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: @font-weight-400;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: #1dab3b;
    display: block;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: -10px;
    width: 10px;
    height: 10px;
    background-color: @green_bg_color;
    display: block;
    border-radius: 100%;
    transition: all 05s ease-in-out;
    animation: pulse 10s infinite ease-in-out;
  }


  @keyframes pulse {

    0%,
    100% {
      background-color: @green_bg_color;
    }

    50% {
      left: 100%;
    }

  }

  @media (max-width:@tablet-width) {
    font-size: 7rem;
  }

  @media (max-width:@tablet-width) {
    font-size: 4rem;
  }

  @media (max-width:@mobile) {
    font-size: 3.5rem;
  }

  @media (max-width:@min-mobile) {
    font-size: 3rem;
  }

}

.afterLoginBtnDivider,
.ant-divider-vertical {
  height: 5rem !important;
  border-inline-start: 1px solid @white_color !important;

}

.boldTitle {
  color: @green_color;
}

.spanTitle {
  color: @green_color;
}

// for heading tiltle and sub title end

//top loging register btn end

// common button start
.ant-btn,
.common_button,
.ant-btn.common_button {
  width: auto;
  height: auto;
  z-index: 1;
  padding: 7px 22px 8px;
  text-align: center;
  border: solid 1px @white_color;
  border-radius: 7px;
  background-color: transparent;
  position: relative;
  font-size: 1.6rem;
  font-weight: 400;
  // line-height: normal;
  overflow: hidden;
  transition: all 500ms ease-in-out, background-color 3s ease;
  cursor: pointer;
  // margin-right: 20px;
  color: @white_color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  outline: none;
  box-shadow: none;

  &:before,
  &:after {
    background: @green_bg_color;
    content: " ";
    display: block;

    position: absolute;
    transform: skewX(-25deg);
    width: 0;
    height: 0;
    z-index: 0;
    opacity: 0;
    transition: all 0.5s linear;
    border-radius: 120px;
  }

  &:before {
    top: -20%;
    left: -20%;
    // border-top-right-radius: 60px;
  }

  &:after {
    bottom: -20%;
    right: -20%;
    // border-top-left-radius: 60px;
  }

  &:is(:hover, :focus, :focus-within) {
    outline: none;
    box-shadow: none;
    // background: @green_bg_color;
    background: #1dab3b87;
    border: solid 1px @green_bg_color;
    transform: scale(1);

  }

  &:hover::before,
  &:hover::after,
  &:focus-within::before,
  &:focus-within::after {
    width: 100%;
    height: calc(100% + 25px);
    opacity: 1;
    top: 0;
    bottom: auto;
    border-radius: 0;
  }

  &:hover::after,
  &:focus-within::after {
    top: auto;
    bottom: 0;
  }

  span {
    z-index: 1;
    position: relative;
    font-family: @primary-font-family;
    font-size: 1.6rem;
    line-height: normal;
    color: currentColor;
  }

  &.web_btn_primary {
    background-color: @whitebg_color;
    color: @green_color;
  }

  &.green_border_common_btn {
    color: @green_color;
    border-color: @green_color;

    &:hover {
      color: @white_color;

    }
  }

  &:hover {
    border-color: @green_color !important;
    color: @white_color !important;
  }

  &:focus {
    background-color: @green_color !important;
    color: @white_color !important;
    border-color: @green_color !important;
  }

  @media (max-width: @tablet-width) {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
  }
}

.bannerBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//top loging register btn  start
.loginBtn {
  .ant-btn,
  .common_button,
  .ant-btn.common_button {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    margin: 0 2rem;

    @media (max-width:@min-mobile) {
      padding: 0.5rem 1rem;
      margin: 0 1rem;
    }
  }
}


// common button end


// ********** <===== website all common from css start login ,forget password, Sign Up start =====> *********
.webLoginSection {
  padding: 7rem 0;
  position: relative;
  overflow-y: auto;
  isolation: isolate;

  min-height: calc(100dvh - 207px);

  @media (max-width:@mobile) {
    height: auto;
  }

  background-image: url("../../../assets/webimage/windBgImg.jpg");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;

  .subtitle {
    font-family: @primary-font-family;
    background: linear-gradient(180deg, #1DAB3B 0%, #009CD1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 4.4rem;
  }

  .webLoginBox {
    width: 90%;
    max-width: 390px;
    margin: 0 auto;
  }

  .ant-picker {
    border: 1px solid @medium_dark_gray_color;
  }

  input,
  .ant-input-password {
    width: 100%;
    background: transparent;
    outline: none;
    padding: 0;
    height: inherit;
    border-radius: 0;
    box-shadow: none;
    border: transparent;
    padding: 14px 12px;
  }

  .ant-input-affix-wrapper {
    &:focus {
      box-shadow: none !important;
      border: 1px solid @green_color;
    }
  }

  .ant-input-affix-wrapper-status-error {
    &:focus {
      input {
        border: 1px solid @red_color;
      }
    }

    input {
      border: 1px solid @red_color;
    }
  }

  .ant-form-item {
    margin-bottom: 2rem;
  }

  .checkboxRememberMe {
    margin-bottom: 0;
  }

  .forgetPass {
    .rememberMe {
      span {
        color: @lite_gray_color;
      }
    }

    .forgotPassLink {
      text-align: right;
    }

    a {
      font-family: @primary-font-family;
      font-size: 1.2rem;
      color: @green_color;
      border-bottom: 1px solid @green_color;
    }
  }

  .logInBtn {
    text-align: center;
    padding-top: 2rem;

    .log_in_button {
      cursor: pointer;
      padding: 1.1rem 8rem;
      font-size: 1.4rem;
      color: @green_color;
      background: transparent;
      border: 1px solid @green_color;
      margin: 0;
    }

    .primery_btn_log_page {
      color: @white_color;
      background: @green_color;
    }
  }

  .ant-divider {

    .ant-divider-inner-text {
      color: @lite_gray_color;
      font-size: 1.2rem;
    }
  }

  .register_here {
    text-align: center;
    color: @medium_dark_gray_color;
    font-weight: @font-weight-400;

    span {
      margin-left: 1rem;
      color: @green_color;
      font-size: 1.4rem;
      font-family: @primary-font-family;
      font-weight: @font-weight-500;
      cursor: pointer;
      border-bottom: 1px solid @green_color;
    }
  }


}

// .ant-checkbox-wrapper-checked {
.ant-checkbox-checked {

  .ant-checkbox-inner {

    background-color: @medium_dark_gray_color !important;
    border-color: @medium_dark_gray_color !important;
  }
}

.ant-checkbox-wrapper {
  &:hover {

    .ant-checkbox .ant-checkbox-inner {
      border-color: @green_color; // for mouse focus color
    }
  }
}

.webRegisterSection {
  .ant-divider {
    margin: 0;
    margin: 1.5rem 0;
  }

  .tearmAndConditiomError {
    font-size: 1.2rem;
    display: block;
    margin: 0 2.6rem;
    // text-align: center;
    color: @red_color;
  }


  .tearmAndConditiomLink {
    font-size: 1.2rem;
    color: @green_color;
    border-bottom: 1px solid @green_color;

    &:hover {

      color: @green_color;
    }
  }


  .subtitle {
    text-align: center;
    padding-bottom: 2.8rem;
  }

  .forgetpassToLog {
    text-align: center;
    color: @medium_dark_gray_color;
    font-size: 1.8rem;

    span {
      font-size: 2rem;
      background: linear-gradient(180deg, #1DAB3B 0%, #009CD1 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      cursor: pointer;
      margin-left: 1.3rem;
    }
  }

  p {
    text-align: center;
    margin: 0;
    padding-bottom: 2.8rem;
    font-size: 1.4rem;
    background: linear-gradient(180deg, #1DAB3B 0%, #009CD1 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .logInBtn {
    margin-top: 1.5rem;
    padding-top: 0;
  }
}

.webForgotPassword {
  

  .webLoginBox {

    max-width: 395px;
    // text-align: center;
  }

  .ant-form-item {
    margin-bottom: 0;
  }



  .otpTitle {
    padding: 5rem 0 1.4rem 0;
    text-align: center;
  }

  .otpInput {
    justify-content: center;
    gap: 3rem;

    input {
      text-align: center;
      border: 1px solid #bfbfbf;
      width: 4.6rem !important;
      height: 5.5rem !important;
      &:hover{
        border: 1px solid @green_color;
      }
      &:focus{
        border: 1px solid @green_color;
      }
    }

    .ant-form-item {
      width: 46px !important;
      height: 46px;
      margin-right: 3rem;

      &:last-child {

        margin-right: 0;

      }
    }
  }
}

.iconBtn {
  border: transparent;

  &:hover {
    border: transparent;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

// ********** <===== website all common from css login ,forget password, Sign Up end =====> *********
.examModal {
  color: @green_color;
  // min-height: calc(100vh - 170px);
  position: relative;
  // overflow-y: auto;
  isolation: isolate;
  background-image: url("../../../assets/webimage/windBgImg.jpg");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;

  h2 {
    text-align: center;
    border-bottom: 1px solid @green_color;
    padding-bottom: 1rem;
    font-size: 3rem;
    font-family: @font-weight-400;
  }

  p {
    text-align: center;
    margin: 3rem 0;
  }

  .logInBtn {
    text-align: center;

    button {
      margin-right: 2rem;

      &:nth-child(2) {
        margin-right: 0rem;
      }
    }
  }
}

.examList {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px;
  padding: 2rem;
  border-radius: 8px;
  background-color: @white_color;

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 38px;
  }

  .icon {
    color: @green_color !important;
  }

  .common_button {
    margin-right: 2rem;
  }

  .listBtn {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-select {
      color: @green_color;

      &:hover {
        .ant-select-arrow {
          svg {
            color: @green_color;
          }
        }

        .ant-select-selector {
          background-color: transparent;
          color: @green_color;

        }
      }
    }

    .ant-select-selector {
      background-color: transparent;
    }

  }
}

.common_modal {
  .ant-modal-header {
    text-align: center;
    padding: 15px 20px;

    .ant-modal-title {
      font-size: 3rem;
      font-family: @secondry-font-family;
    }

  }

  // .ant-modal-close .ant-modal-close-x {
  //   height: auto;
  // }

  svg {
    color: @white_color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-footer {
    text-align: center;
    background-color: @modal_footer_bg_Color;
  }
}

.previewImage {
  .ant-modal-header {
    text-align: center;
    padding: 15px 20px;

    .ant-modal-title {
      font-size: 3rem;
      font-family: @secondry-font-family;
    }

  }

  .ant-modal-body {
    text-align: center;
  }

  .ant-modal-close .ant-modal-close-x {
    height: auto;
  }

  svg {
    color: @green_color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-footer {
    text-align: center;
    background-color: @modal_footer_bg_Color;
  }

}

.interesQnaSection {
  max-width: 1600px;
  margin: 0 auto;
  color: @green_color;


  // padding: 3.5rem;
  min-height: 50rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .radioGrpExam {
    margin-bottom: 2rem;
    .ant-select-single {
      height: 42px !important;
  }
  }





  .webLoginSection {
    padding: 0;
  }

  .profileLayout {
    padding: 5.5rem 15rem;
  }

  .step_content {
    color: @green_color;
   .infoCircle{
     color: @gray_color;
     margin-left: 0.5rem;
     display: inline-block;
     vertical-align: top;
    }

    .currentAns {
      margin-right: 0.5rem;
    }
  }

  .currentAns {
    font-size: 2rem;

  }

  .radioGrpExamIcon {
    text-align: end;
    display: flex;
    align-items: center;

    .title {
      color: @black_color !important;
    }


    .ant-radio-wrapper {
      margin: 0;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      width: 6.2rem;
      gap: 0.2rem;

      &::after {
        display: none;
      }
    }
  }

  .radioGrpExamIconHideTitle {
    .title {
      display: none;
    }

  }

  .examRadioGrp {

    .ant-radio {
      display: none;
    }

    .ant-radio-wrapper-checked {
      .agree {

        svg,
        path {
          fill: #02A552;
          color: #02A552;
        }
      }
    }

    .ant-radio-wrapper-checked {

      .strongly_agree {

        svg,
        path {
          fill: #01A553;
          color: #01A553;
        }
      }
    }

    .ant-radio-wrapper-checked {
      .neutral {

        svg,
        path {
          fill: #FECC04;
          color: #FECC04;
        }
      }
    }

    .ant-radio-wrapper-checked {
      .strongly_disagree {

        svg,
        path {
          fill: #FD0000;
          color: #FD0000;
        }
      }
    }

    .ant-radio-wrapper-checked {
      .disagree {

        svg,
        path {
          fill: #F48220;
          color: #F48220;
          stroke: #F48220;
        }
      }
    }
  }

  .radio-img {
    input {
      // display: none;
      opacity: 0;
    }

    .image {
      cursor: pointer;
      transition: 0.3s all ease-in-out;

      svg {
        transition: 0.3s all ease-in-out;
        height: 47px;
        width: 46px;

      }
    }



    input:checked+.agree {

      svg,
      path {
        fill: #02A552;
        color: #02A552;
      }
    }

    input:checked+.strongly_agree {

      svg,
      path {
        fill: #01A553;
        color: #01A553;
      }
    }

    input:checked+.neutral {

      svg,
      path {
        fill: #FECC04;
        color: #FECC04;
      }
    }

    input:checked+.strongly_disagree {

      svg,
      path {
        fill: #FD0000;
        color: #FD0000;
      }
    }

    input:checked+.disagree {

      svg,
      path {
        fill: #F48220;
        color: #F48220;
        stroke: #F48220;
      }
    }
  }

  .strongly_agree {
    &:hover {

      svg,
      path {
        opacity: 1;
        fill: #01A553;
        color: #01A553;
      }
    }
  }

  .agree {
    &:hover {

      svg,
      path {
        fill: #02A552;
        color: #02A552;
      }
    }
  }

  .neutral {
    &:hover {

      svg,
      path {
        fill: #FECC04;
        color: #FECC04;
      }
    }
  }

  .strongly_disagree {
    &:hover {

      svg,
      path {
        fill: #FD0000;
        color: #FD0000;
      }
    }
  }

  .disagree {
    &:hover {

      svg,
      path {
        fill: #F48220;
        color: #F48220;
        stroke: #F48220;
      }
    }
  }

  .image {
    display: inline-block;

    svg {
      height: 4.2rem;
      width: 4.2rem;
    }

  }



  .currentQuestion {
    font-size: 1.8rem;
    font-weight: @font-weight-400;
    word-wrap: break-word;

  }


  .totalAns {

    font-weight: @font-weight-400;
    font-size: 2rem;

    .allAns {
      border-radius: 20px 20px 0px 0px;
      background: linear-gradient(90deg, #009CD1 7.89%, #1DAB3B 97.1%);
      padding: 1.5rem;
      color: @white_color;
    }
  }



  .ansListRadiobtn {
    // margin: 1rem 0 0 3rem;
    padding: 3.5rem;
    border: 1px solid @green_color;
    border-top: transparent;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;

    .logInBtn {
      text-align: right;

      .ant-btn {
        display: inline-block;
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .examPdf {
      &:hover {
        color: @green_color;

        svg {
          color: @green_color;
        }
      }

      svg {

        height: 5rem;
      }
    }
  }

  .ansList {
    .ant-radio-wrapper {
      margin: 0;

      &:hover {
        .ant-radio-inner {
          border-color: @green_color ;
        }
      }
    }

    .ant-radio-wrapper-disabled {
      &:hover {
        .ant-radio-inner {
          border-color: @lite_gray_color ;
        }
      }
    }

    .ant-radio-wrapper-checked {


      .ant-radio-inner {
        border-color: @green_color;
        background-color: @green_color;
      }

    }

    .ant-radio-wrapper {
      display: block;
      font-size: 1.8rem;

      span {
        color: @green_color;
      }
    }
  }

  .logInBtn {
    margin-top: 2rem;

    button {

      margin: 2.5rem;

      &:nth-child(2) {
        margin: 0;
      }
    }

    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-top: 1px solid @green_color;
      height: auto;
      width: 100%;
    }
  }


  @media (max-width: @1300-width) {
    .radioGrpExamIcon {

      .ant-radio-wrapper {
        width: 6rem;

        .title {
          font-size: 1.3rem;
        }

      }


    }
  }

  @media (max-width:@mobile) {
    .answers {
      text-align: start;
    }

    .ansListRadiobtn {

      padding: 1.5rem;
    }


    .radioGrpExamIcon {
      text-align: start;
    }

    .step_content {
      color: @green_color;
      margin: 2rem 0 0.5rem 0;
    }

    .radioGrpExamIcon {

      .ant-radio-wrapper {
        width: 5rem;

        .title {
          font-size: 1.1rem;
          display: block;
        }

      }

    }

  }




  @media (max-width:@min-mobile) {
    .currentQuestion {
      font-size: 1.6rem;
    }

    .image {
      svg {
        height: 32px;
        width: 32px;
      }
    }

  }

}

.logOutModal {
  .ant-modal-header {
    background-color: @modal_logOut_color;
    text-align: center;

    .ant-modal-title {
      font-size: 3rem;
      font-family: @secondry-font-family;
    }

  }

  .ant-modal-close-x {
    height: auto;


    svg {
      height: auto;
      // color: @modal_logOut_color;
    }
  }

  .ant-modal-body {
    font-size: 1.8rem;
    text-align: center;



    span {
      font-size: 2rem;
      font-weight: @font-weight-500;
    }
  }

  .ant-modal-footer {
    text-align: center;
    background-color: @modal_footer_bg_Color;
  }


}

.red_border_common_btn {
  color: @modal_logOut_color;
  border-color: @modal_logOut_color;

  &:before,
  &:after {
    background: @modal_logOut_color;
    content: " ";
    display: block;

    position: absolute;
    transform: skewX(-25deg);
    width: 0;
    height: 0;
    z-index: 0;
    opacity: 0;
    transition: all 0.5s linear;
    border-radius: 120px;
  }

  &:before {
    top: -20%;
    left: -20%;
  }

  &:after {
    bottom: -20%;
    right: -20%;
  }

  &:is(:hover, :focus, :focus-within) {
    outline: none;
    box-shadow: none;
    background: @modal_logOut_color;
    border: solid 1px @modal_logOut_color;
    transform: scale(1);

  }

  &:hover::before,
  &:hover::after,
  &:focus-within::before,
  &:focus-within::after {
    width: 100%;
    height: calc(100% + 25px);
    opacity: 1;
    top: 0;
    bottom: auto;
    border-radius: 0;
  }

  &:hover::after,
  &:focus-within::after {
    top: auto;
    bottom: 0;
  }

  span {
    z-index: 1;
    position: relative;
    font-family: @primary-font-family;
    font-size: 1.6rem;
    line-height: normal;
    color: currentColor;
  }

  &.web_btn_primary {
    background-color: @whitebg_color;
    color: @modal_logOut_color;
  }

  &.green_border_common_btn {
    color: @modal_logOut_color;
    border-color: @modal_logOut_color;

    &:hover {
      color: @white_color;

    }
  }

  &:hover {
    border-color: @modal_logOut_color !important;
    color: @white_color !important;
  }

  &:focus {
    background-color: @modal_logOut_color !important;
    color: @white_color !important;
    border-color: @modal_logOut_color !important;
  }

  @media (max-width: @tablet-width) {
    // padding: 7px 17px;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
  }
}

.testimonial {

  b {
    font-weight: @font-weight-700;
  }

  .sliderSection {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
  }

  .noSlider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    .slide-item {
      min-height: 47rem;
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      border-radius: 20px;
      background: @white_color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 3rem 2.5rem;
      width: 350px;
      box-sizing: border-box;

      &::after {
        content: "";
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        background-image: url("../../../assets/webimage/testimonialQoutes.svg");
        background-repeat: no-repeat;
        height: 8.6rem;
        width: 10.6rem;
        display: block;

      }

      .details {
        font-size: 1.2rem;
        font-weight: @font-weight-300;

        span {
          display: block;
          margin-bottom: 1rem;
          font-size: 2.2rem;
          font-weight: @font-weight-400;
        }
      }

    }

    @media (max-width:@mobile) {
      flex-direction: column;
      margin-bottom: 5rem;
    }
  }

  .testimonialSlider {
    p {
      font-size: 1.6rem;
      font-weight: @font-weight-300;
      line-height: 1.6;
    }

    .slick-track {
      display: flex;
      align-items: center;
      justify-content: stretch;
      padding: 10px 0;
    }

    .slick-slide {
      border-radius: 20px;
      background: @white_color;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 3rem 2.5rem;
      margin: 0 2.5rem;
      width: 350px;
      min-height: 42rem;
      box-sizing: border-box;

      >div {
        min-height: 42rem;

        .slide-item {
          min-height: 42rem;
          display: flex !important;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background-image: url("../../../assets/webimage/testimonialQoutes.svg");
            background-repeat: no-repeat;
            height: 8.6rem;
            width: 10.6rem;
            display: block;

          }

          .details {
            font-size: 1.2rem;
            font-weight: @font-weight-300;

            span {
              display: block;
              margin-bottom: 1rem;
              font-size: 2.2rem;
              font-weight: @font-weight-400;
            }
          }
        }
      }
    }


  }



  .sliderNav {
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .sliderArrow {
      cursor: pointer;
      transition: 0.5S all ease-in-out;

      &:hover {
        scale: 1.1;
      }

      &.rightArrow {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: @1300-width) {
    padding: 0rem;

    .sliderSection {
      width: 100%;
    }

    .testimonialSlider {

      .slick-slide {
        margin: 0 1rem;
      }
    }

  }

  @media (max-width: @tablet-width) {
    .testimonialSlider {

      .slick-slide {
        margin: 0 2.5rem;
      }
    }
  }

  @media (max-width:@mobile) {
    .testimonialSlider {
      .slick-slide {
        max-width: initial;
        min-height: 40rem;
      }

      .slick-slide {

        >div {
          min-height: 40rem;

          .slide-item {
            min-height: 40rem;

          }
        }
      }
    }
  }
}

.community_section_description {
  text-align: center;
  margin: 9rem 0;

  .community_section_contactus {
    margin: 2rem;
  }

  @media (max-width:@tablet-width) {
    margin: 5rem 0;
  }
}

.uploadFile {
  .ant-btn {
    &:hover {

      &::before,
      &::after {
        display: none;
      }
    }
  }


}

.hideDeleteIcon .ant-btn.ant-upload-list-item-action:has(.anticon-delete) {
  display: none;
}

.skillSection {

  .ant-radio-wrapper {
    .ant-radio-inner {
      height: 2.5rem;
      width: 2.5rem;


    }

    .ant-radio-checked {

      .ant-radio-inner {
        border-color: @green_color;
        .themeColorOpacity(@green_color, 0.5);
        box-shadow: 0px 0px 8px 5px rgba(29, 171, 59, 0.3);


        &::after {
          background-color: @green_color;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          transform: scale(1);
          left: 12px;
          top: 12px;


        }
      }
    }

  }
}

.examTooltip {
  .ant-tooltip-inner {
    color: @black_color;
    background-color: #F3F3F3;
  }

  .ant-tooltip-arrow {
    &:before {
      background-color: #F3F3F3;
    }
  }
}

.reportSection {
  max-width: 1627px;

  .fullGraph {
    text-align: center;
    justify-content: center;

    svg {
      width: 100%;
    }

  }

  .allAns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ansListRadiobtn {
    .graphImage{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      svg{
        height: fit-content;  
        width: 90%;
      }
      
    }
    min-height: calc(100vh - 365px);
    height: auto;



    .colorInformation {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      width: 100%;

      .colorBox {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .interestColorBox {
        background-color: @green_color;
        height: 3rem;
        width: 3rem;
        display: inline;
      }

      .skillColorBox {
        background-color: #009CD1;
        height: 3rem;
        width: 3rem;
        display: inline;
      }
    }
    .graphInst{
      text-align: center;
      color: @black_color;
      span{
        color: @green_color;
      }
    }
  }

  .reportBar {
    margin-bottom: 8rem;
  }

  .downloadAndInfo {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    .downloadReport {
      border: 0.1rem solid @white_color;
      padding: 0.7rem 2rem;
      border-radius: 5rem;
      cursor: pointer;

      .downloadTitle {
        font-size: 1.6rem;
      }
      transition:0.5s all ease-in-out;
      &:hover{
        scale: 0.9;
      }
    }

    .downloadReportTooltip {
      display: none;
    }

    .reportInfo {
      cursor: pointer;

      svg {
        height: 2.4rem;
        width: 2.4rem;
      }
    }
  }

  .reportContentWrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }

  .reportContent {
    border: 2px solid @green_color;
    padding: 1.5rem;
    border-radius: 2.5rem;
    transition: 0.5s all ease-in-out;
    max-width: 33rem;
    width: 100%;
    overflow: hidden;
    word-wrap: break-word;


    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .categoryTitle {
    color: @black_color;

    h3 {
      font-weight: 700;
      margin: 0.2rem 0;
      font-size: 1.5rem;
    }
  }

  .reportName {
    color: @black_color;

    p {

      font-size: 1.4rem;
    }
  }

  .seeMore {
    text-align: center;

    span {
      cursor: pointer;
      position: relative;
      transition: 0.3s all ease-in-out;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
        bottom: 0;
        border-bottom: 1px solid @green_color;
      }
    }



    span {
      &:hover {
        color: @logo_blue_color;

        &::after {
          border-bottom: 1px solid @logo_blue_color;
        }
      }
    }


  }

  .feedBack {
    margin-top: 5rem;
    text-align: center;

    p {
      color: @black_color;

      a {
        color: @green_color;

        &:hover {
          color: @logo_blue_color;
        }
      }
    }
  }

  .ant-progress {
    .ant-progress-inner {
      border-radius: 0;
      width: 90%;
    }

    .ant-progress-bg {
      border-radius: 0;
    }
  }

  @media (max-width:@desktop) {
    .fullGraph {
      .graph {
        margin: 0 auto;
      }
    }

    .currentAns {
      font-size: 1.6rem;
    }

    .downloadAndInfo {
      .downloadReport {
        padding: 0.5rem 1rem;

        .downloadTitle {
          font-size: 1.3rem;
        }
      }
    }

    .reportBar {
      margin-bottom: 8rem;
    }
  }

  @media (max-width:@tablet-width) {


    .downloadAndInfo {

      .downloadReport {
        display: none;
      }


      .downloadReportTooltip {
        display: block;
      }
    }


  }

  @media (max-width:@mobile) {
    .currentAns {
      font-size: 1.4rem;
    }

    .ansListRadiobtn {
      min-height: calc(100vh - 365px);
      height: auto;
      position: relative;


      .colorInformation {
        position: initial;
        display: block;

        .colorBox {
          margin-top: 1rem;
        }
      }
    }

    .reportBar {
      margin-bottom: 3rem;
    }

    .reportContent {
      max-width: 100%;

    }
  }

}

.analystModal {

  * { font-family: @primary-font-family !important;
  }

  .analystTitle {
    font-weight: 600;
    font-size: 1.5rem;
    color: @green_color;
  }
  .firstColumn{
    .cmsInnerContent{
      margin-bottom: 2rem;
    }
    .careerResultImage, .careerResultVideo{
      margin-top:1rem;
    }
  }
  .cmsInnerContent{
    div,  p {      
      color: rgba(0, 0, 0, 0.88);
      line-height: 22px;
      margin: 0.5rem 0;
      font-size: 14px;
      font-weight: @font-weight-400;
      font-family: @primary-font-family;

  @media (max-width: @tablet-width) {
    font-size: 1.4rem;
  }
    }
  }

  .analystContent {
    font-size: 1.1rem;
    font-weight: 400;
    color: @gray_color;
    line-height: 1.6;
  }

  .boxContent {
    margin-bottom: 1.5rem;
  }

  .boxTitle {
    background-color: @green_bg_color;
    color: @white_color;
    padding: 1rem;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  .boxInnerContent {
    border: 1px solid @green_color;
    margin-bottom: 1.5rem;
    border-top: 0;
    padding: 1.1rem; 
    .requirementSubHeader {
      margin-bottom: 1rem;
      h4 {
        padding: 0.5rem;
        background-color:@green_bg_color;
        color: @white_color;
        font-size: 1.5rem;
      }
      .cmsInnerContent{
        padding:0.5rem;
      } ;
      .notAvailable {
        padding: 0.5rem 
      }
    }
    h5 {
      font-size: 1.3rem;
      font-weight: 600;
      color: @dark_black_color;
    }

    .analystModal .firstColumn .cmsInnerContent, p, li {
      color: rgba(0, 0, 0, 0.88);
      line-height: 22px;
      margin: 0.5rem 0;
      font-size: 14px;
      font-weight: @font-weight-400;
      font-family: @primary-font-family;
    }

    .ant-divider {
      margin: 1rem 0;
    }
  }
}