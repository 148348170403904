// Third Party Stylesheets
// @import "~antd/dist/reset.css"; // ant-d V5.1.0
@import "~antd/dist/reset.css"; // Import Ant Design styles by less entry
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

// Theme Variables
//@ import "~antd/lib/style/themes/default.less";
@import "variables.less";

// Common and Overrides Stylesheets
@import "./utils/utilities.less";
@import "./common.less";
@import "./override.less";

// Layout Stylesheets
@import "./layout/app-header.less";
@import "./layout/app-sidebar.less";

// Utils Stylesheets
@import "./utils/container.less";

// Sections Stylesheets

// Views Stylesheets
@import "./views/auth.less";
@import "./views/form.less";
@import "./views/general.less";
@import "./views/dashboard.less";

//web style sheets
@import "./views/web/webVariables.less";
@import "../../views/webPages/style/AggridCommon.less";