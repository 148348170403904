.ag-cell {
    line-height: 1.9 !important;
}

.ag-grid-wrapper {
    height: 100%;
    font-family: @primary-font-family;
   
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
   
    overflow: hidden;
    

    .ag-overlay {
        position: inherit;
    }

    .ag-root-wrapper {
        border: 0;

        .ag-header-cell {
            border: 0;
            font-size: 13px;
            font-weight: 500;
            color: #616161;
            padding-left: 10px;
            padding-right: 10px;

            // .ag-header-cell-label {
            //   justify-content: center;
            // }
            .ag-header-cell-text {
                color: @text_dark_black;
                font-size: 14px;
                letter-spacing: 0.5px;
                font-weight: 600;
            }
        }
    }

    .ag-pinned-right-header {
        border: 0;
    }

    .ag-pinned-left-header {
        border: 0;
    }

    .ag-header {
        background-color: @white_color;
        border: 0;
    }

    .ag-floating-filter {
        background-color: transparent;
    }

    .ag-ltr .ag-cell {
        border: 0;
        font-size: 14px;
        color: @text_light_black;
        font-weight: 400;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
    }

    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
       border-right: transparent;
    }
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
        border-left: transparent;
    }

    .ag-row {
        border-bottom: 1px solid #f0f0f0;
    }

    .ag-row-odd {
        background-color: transparent;
    }

    .ag-row-even {
        background-color: transparent;
    }

    .ag-row-hover:not(.ag-full-width-row)::before,
    .ag-theme-alpine .ag-row-hover.ag-full-width-row.ag-row-group::before {
        .themeColorOpacity(@primary-color, 0.1);
    }
    .ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total){
        border-top: transparent;
    }
}