.mapModal {
  padding: 0;
  border-radius: 8px;
  max-width: 700px;
  width: 100% !important;
  overflow: hidden;

  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
  }

  .ant-modal-body {
    padding: 24px;
  }
  .submitBtn {
    padding: 15px 0px;
    text-align: center;
    width: 100%;
    max-width: 130px;
    transition: all 0.5s ease-in-out;
    border: solid 1px;
    height: auto;
    text-shadow: none;
    box-shadow: none;
    line-height: normal;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    background-color: #f05427;
    border-radius: 6px;
    border-color: #f05427;
    color: #ffffff;

    &:hover {
      color: #323443;
      background-color: transparent;
      border-color: #d9d9d9;
    }
  }
  .footer_bottom {
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    line-height: normal;
    color: #323443;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    b {
      display: block;
      padding-top: 5px;
      &:empty {
        display: none;
      }
    }
    span {
      width: 70%;
    }
  }
}

.map-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .ant-form {
    width: 100%;
  }
  &__map {
    height: 400px;
    width: 100%;
  }
}

.map_blinking_marker {
  background: red;
  border-radius: 10px;
  height: 10px;
  position: relative;
  width: 10px;

  &::before {
    animation: blink 1s infinite ease-out;
    border-radius: 60px;
    box-shadow: inset 0 0 0 1px red;
    content: "";
    height: 10px;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
  }
}
