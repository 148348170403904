@import "../../../../assets/styles/views/web/webVariables.less";



.web-dashboard-section {



    .web-dashboard-counter {
        border-radius: 10px;
        border: 1px solid #CBCBCB;
        background: rgba(255, 255, 255, 0.96);
        padding: 2rem 2rem 1rem 2rem;
        position: relative;
        overflow: hidden;
        min-height: 151px;
        transition: 0.5s all ease-in-out;

        &:hover {
            cursor: pointer;
            transform: translateY(-5px);
            box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
        }


        .web-dashboard {
            text-align: end;
            position: absolute;
            bottom: 10px;
            right: 20px;

            .rightArrow {
                position: relative;
                z-index: 1;

                &::before {
                    content: "";
                    border-radius: 50% 50% 0 0/100% 100% 0% 0%;
                    background-color: #1DAB3B;
                    display: inline-block;
                    margin: 10px;
                    color: white;
                    text-align: center;
                    vertical-align: top;
                    overflow: hidden;
                    font-size: 15px;
                    right: -90px;
                    top: -32px;
                    position: absolute;
                    width: 145px;
                    height: 125px;
                    line-height: 290px;
                    transform: rotate(-45deg);
                    z-index: 0;


                }
            }

            svg {
                fill: @white_color;
                position: relative;
                z-index: 1;

            }



        }

        .half-circle-top {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
        }
    }

    .web-dashboard-counter-exam-content {
        display: flex;
        gap: 4.6rem;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            border-radius: 50% 50% 0 0/100% 100% 0% 0%;
            background-color: #1DAB3B;
            display: inline-block;
            margin: 10px;
            color: white;
            text-align: center;
            vertical-align: top;
            overflow: hidden;
            font-size: 15px;
            left: -73px;
            top: -45px;
            position: absolute;
            width: 170px;
            height: 105px;
            transform: rotate(136deg);
            z-index: 0;
        }

        // clip-path: circle(51.0% at 9% 10%);


        svg {
            height: 50px;
            width: 50px;
            position: relative;
            z-index: 1;
            fill: @white_color;
        }

        p {
            margin: 0;
            font-size: 2rem;
            color: @green_color;
        }


    }

    .comingSoonExam {
        text-align: center;

    }

    .web-deshboard-profile {
        justify-content: end;
        text-align: end;
        padding-bottom: 2rem;
    }

    .comingSoon {

        .web-dashboard-counter-exam-content,
        .rightArrow {
            &::before {
                background-color: rgba(117, 117, 117, 0.50) !important;
            }
        }

        p {
            color: rgba(117, 117, 117, 0.50);
            margin: 0;

        }

        .comingSoonExam {
            color: @green_color;
            font-size: 4rem;
            margin-top: -18px;
            position: relative;
            z-index: 2;
            font-weight: 500;
            text-shadow: 1px 4px 2px rgba(0, 0, 0, 0.6);
        }

    }

    .disabledBox {

        .web-dashboard-counter-exam-content,
        .rightArrow {
            &::before {
                background-color: #757575 !important;
            }
        }

        p {
            color: #757575;
            margin: 0;
        }


    }

    @media (max-width: @desktop) {
        .web-dashboard-counter-exam {
            p {
                font-size: 1.5rem;
            }
        }
    }
}