  @import (css) url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Teko:wght@400;500;600;700&display=swap');

  // <--- font-family start --->
  @primary-font-family : 'Poppins',
  'Trebuchet MS',
  'Lucida Sans Unicode',
  'Lucida Grande',
  'Lucida Sans',
  Arial,
  sans-serif;
  @secondry-font-family : 'Teko',
  'Trebuchet MS',
  'Lucida Sans Unicode',
  'Lucida Grande',
  'Lucida Sans',
  Arial,
  sans-serif;
  // <--- font-family end --->

  // <---font-colors-start--->
  @black_color: #000000;
  @white_color: #ffffff;
  @dark_black_color: #171717;
  @green_color: #1DAB3B;
  // <---font-colors-end--->

  // <---background Color start--->
  @grey_bg_color: #F3F3F3;
  @green_bg_color: #1DAB3B;
  @dark_grey_bg_color: #F9F9F9;
  @whitebg_color: #ffffff;
  @lite_gray_color :rgb(191, 191, 191);
  @medium_dark_gray_color :#989898;
  @gray_color:#757575;
  @logo_blue_color: #31BEE1;
  @red_color: #ff4d4f;
  @modal_footer_bg_Color: #b2b4b512;
  @modal_logOut_color: #ff3131;
  // <---background Color end--->

  // <--- font-weight-start --->
  @font-weight-300: 300;
  @font-weight-400: 400;
  @font-weight-500: 500;
  @font-weight-600: 600;
  @font-weight-700: 700;
  @font-weight-800: 800;
  @font-weight-900: 900;
  // <--- font-weight-end --->

  // responsiv - start
  @1750-screen : 1750px;
  @1650-screen : 1650px;
  @big-desktop : 1400px;
  @1300-width: 1300px;
  @desktop :1199px;
  @tablet-width: 991px;
  @mobile : 767px;
  @min-mobile : 600px;

  // responsiv - end
  .themeColorOpacity(@rgbColor, @opacity) {
    background-color: rgba(@rgbColor, @opacity);
  }