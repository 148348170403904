img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: @text-color;
}


// svg {
//   max-width: 100%;
//   max-height: 100%;
// }

.main__app__content {
  // padding: 15px;
  min-height: calc(100vh - 230px);
  // overflow-y: auto;
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.6);
}

::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.loginForm .ant-form .ant-input:-webkit-autofill,
.loginForm .ant-form .ant-input:-webkit-autofill:hover,
.loginForm .ant-form .ant-input:-webkit-autofill:focus,
.loginForm .ant-form .ant-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px @primary-color inset !important;
}

.fullscreen__spinner__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .ant-spin-dot-item {
    background-color: @secondary-color;
  }
}

.userMenuList {
  .ant-dropdown-menu {
    padding: 6px 0;
  }
}

.headerBtn {
  .addBtn {
    padding: 12px 14px;
    height: auto;
    line-height: 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border-color: #d0d0d0;
    margin-left: 0;
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    transition: all 0.5s ease;

    &:hover {
      color: @white_color;
      border-color: @primary-color;
      background: @primary-color;

      svg {
        color: @white_color;
      }
    }
  }
}

.iconBtn {
  width: 42px;
  height: 42px;
  background-color: @white_color;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
  border: 0;
  padding: 6px;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.5s ease;
  }

  &:hover svg {
    fill: @primary-color;
  }
}

.ag-root-wrapper-body.ag-layout-normal {
  flex: 1 1 auto;
  height: 100% !important;
  min-height: 0;
}

.deleteNote {
  font-size: 16px;
  color: #939393;
  font-weight: 500;
  text-align: center;

  span {
    color: #444;
  }
}

.ag-grid-wrapper .ag-cell {
  .ant-select-single:not(.ant-select-customize-input) {
    margin: 0 2px;
    max-width: 230px;

    .ant-select-selector {
      height: 32px;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 5px;

      .ant-select-selection-search-input {
        height: 32px;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 32px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
  .ant-select:not(.ant-select-multiple) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 31px;
  }
}

.redNote {
  color: @error-color;
  margin-bottom: 15px;
}



// web form css start
.ant-form-vertical {
  font-family: @primary-font-family;

  .ant-form-item-label>label {
    font-size: 1.6rem !important;
    font-family: @primary-font-family !important;
  }
  input, .ant-select-selection-item {
    font-family: @primary-font-family !important;
    font-size: 1.6rem;
  }

  .ant-form-item-label {
    font-family: @primary-font-family;

    .ant-form-item-required {

      &::after {
        content: "*";
        color: @green_color;
        visibility: visible;

      }
    }
  }

  .ant-form-item-has-error {
    .ant-form-item-label {
      .ant-form-item-required {
        &::after {
          color: #F00;

        }
      }
    }
  }
}

.ant-form-item-control {
  .ant-select-focused {
    outline: none;
    border-color: @green_color ;
  }

  .ant-picker {

    .ant-picker-input {
      input {
        // padding: 10px 14px;

        &:hover,
        :focus {
          border-color: @green_color ;
        }
      }
    }
  }

  .ant-picker-status-error {
    background-color: #f3f5f7 !important;
  }

  .ant-form-item-control-input-content {
    input {
      border: 1px solid @lite_gray_color;

      &:hover {
        border: 1px solid @green_color;
      }

      &:focus {
        border: 1px solid @green_color;
      }
    }

    .ant-picker-input {
      input {
        border: transparent;
      }
    }

    .ant-input-password {
      border: 1px solid @lite_gray_color;

      input {
        border: transparent;
      }

      &:hover {
        border: 1px solid @green_color;
      }


    }

    .ant-input-affix-wrapper-focused {
      box-shadow: none !important;
      border: 1px solid @green_color;

      input {
        border: transparent !important;
      }

    }


    .ant-select-selector {
      padding: 0px 12px !important;
      border: none;

      .ant-select-selection-search {
        left: 0 !important;
        right: 0 !important;

        input {
          height: 36px !important;
          padding: 0px 12px !important;
        }
      }
    }
  }

  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background-color: @white_color;
  }
}

// .ant-select-item {
.ant-select-dropdown {
  .ant-select-item-option-selected {
    .themeColorOpacity(@green_color, 0.7) !important;
    // background-color: @green_color !important;
    color: @white_color !important;
  }
}

// }

// web form css end
//side notification start
body {
  .ant-notification-notice {
    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        margin-bottom: 0 !important;
      }
    }
  }
}

//side notification end


.all-career-main {
  a{
    line-height:unset
  }
  a:hover{
    color: #1dab3b;
  }
  .common_button.green_border_common_btn.rounded{
    border-radius: 50%;
    padding: 0;
    height: 40px;
    width: 40px;
  }
  .common_button.green_border_common_btn.rounded{
    border-radius: 50%;
    padding: 0;
    height: 40px;
    width: 40px;
  }
  .interesQnaSection .ansListRadiobtn{
    border-radius: 2rem;
  }
  .ant-table-thead th{
    font-size: 15px;
    font-weight: bolder;
  }
  .ant-pagination .ant-pagination-item-active{
    border-color:rgb(29 171 59) ;
  }
  .td.ant-table-cell{
    font-size: 16px;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,.ant-tabs .ant-tabs-tab:hover{
    color:rgb(29 171 59),
  }
  // .ant-tabs-nav .ant-tabs-tab  {
  //   border: 0.5px solid rgb(29 171 59);
  // }
  .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
  }

  .all-career-details{
    .boxInnerContent{
      border-top: 1px solid #1dab3b;
    }
  }
  .ant-pagination .ant-pagination-item-active a{
    color:rgb(29 171 59);
  }

  .ant-btn.green_border_common_btn:focus,.ant-btn.green_border_common_btn:hover,.ant-btn.green_border_common_btn::before,.ant-btn.green_border_common_btn::after{
    background-color: #ffffff !important;
    color: #1DAB3B !important;
    border-color: #1DAB3B !important;
  }


}