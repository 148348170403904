@import "../../../../assets/styles/views/web/webVariables.less";

.cmsMainPage {
    .cmsInnerContent {
        margin-bottom: 9rem;
        table{
            td{
                padding: 0px 10px 0px 10.4px !important;
                width: 50px !important;
            }
            width: 100% !important;
        }
        span{            
            font-size: 1.8rem !important;
            font-family: inherit !important;
        }
        ul, li{
            list-style: disc !important;
            line-height: 1.6 !important;
            margin-left: 20px;
        }
            p {
                margin-left: 0 !important;
                line-height: 1.6 !important;
            }
    }
ul{
    list-style: disc !important;
}
    p {
        span{            
            font-size: 1.8rem !important;
        }
        line-height: 1.6 !important;
    }

    .content_section {
        margin: 5rem 0;
    }

    .cmsPages {
        background-image: url("../../../../assets/webimage/cmspagebg.png");
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        padding: 7rem;
        position: relative;

        &::after {
            background-color: rgba(0, 0, 0, 0.35);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        .logoImg {
            z-index: 1;
            position: relative;
            height: 264px;
            cursor: pointer;

            .logo {
                height: 100%;
            }
        }





    }

    @media (max-width:@min-mobile) {
        .cmsPages {

            .logoImg {
                text-align: center;
                height: 150px;
            }
        }
    }
}