@import "../../../assets/styles/views/web/webVariables.less";
@import "./webCommon.less";

// banner section css start

.bannerSection {
    // background-image: url("../../../../assets/webimage/banner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    // height: 100dvh;
    text-align: center;
    padding: 50px 0 287px 0;
    color: @white_color;
    text-transform: uppercase;
    position: relative;

    .web_btn_primary {
        padding: 15px 22px 15px;
        @media (max-width:@min-mobile) {
            padding: 10px 15px 10px;
        }
    }

    &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        top: 0;
        // background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0.00) 46.79%, #000 83.60%);
        background-color: rgba(0, 0, 0, 0.35);
        background-repeat: no-repeat;
        background-size: cover;
        // z-index: -1;
    }

    .banner {
        position: relative;
        z-index: 1;
    }

    .content {
        position: inherit;
    }

    .logoImg {
        cursor: pointer;
        height: 274px;
        width: 274px;
        margin: 0 auto 30px;


        .logo {
            width: 100%;
            transition: 0.5s all ease-in-out;

            &:hover {


                transform: scale(1.1);

            }
        }
    }



    .title {
        font-family: @secondry-font-family;
        font-size: 11rem;
        font-weight: @font-weight-400;
    }

    @media (max-width: @desktop) {
        .title {
            font-size: 12rem;
        }
    }

    @media (max-width: @tablet-width) {

        padding: 40px 0 150px 0;

        .logoImg {
            height: 150px;
            width: 150px;

        }

        .subtitle {
            font-size: 3rem;
        }

        .title {
            font-size: 6rem;
        }

    }

    // @media (max-width: @tablet-width) {
    //     .subtitle {
    //         font-size: 3rem;
    //     }

    //     .title {
    //         font-size: 6rem;
    //     }
    // }

    @media (max-width: @mobile) {
        .content {
            margin-top: 3rem;
        }

        .logoImg {

            height: 120px;
            width: 130px;

        }

        .subtitle {
            font-size: 2rem;
        }

        .title {
            font-size: 5.5rem;
        }
    }
}

// banner section css end


// counter section css start
.counterWrap {
    margin-top: -100px;
    z-index: 1;
    position: relative;

    // @media (max-width:@tablet-width) {
    //     margin-top: -5rem;
    // }

    .dotedImg {
        position: absolute;
        top: 10rem;
        left: -3rem;
        z-index: -1;
    }

    .couterSection {
        border-radius: 18px;
        background-color: @green_bg_color;
        background-image: url("../../../assets/webimage/counter_pattern.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 60px;
    }

    .counterContent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 200px;

    }

    .counterUserImg {

        width: 89px;
        height: 89px;

        // margin-bottom: 2rem;
        @media (max-width:@desktop) {
            margin-bottom: 1rem;
        }

    }

    .counterInnerContent {
        display: flex;
        align-items: center;
        gap: 1rem;

        .numberContent {
            color: @white_color;

            span {
                font-family: @secondry-font-family;
                font-size: 7rem;
                color: @green_color;
                text-shadow: 1px -1px 0px @white_color, -1px 1px 0px @white_color, -1px -1px 0px @white_color, 1px 1px 0px @white_color;
                font-weight: @font-weight-500;
                // line-height: 1.5;
                letter-spacing: 0.1rem;
            }

            .conterTitle {
                font-family: @secondry-font-family;
                font-size: 3.5rem;
                line-height: 0.9;
                font-weight: @font-weight-300;
                letter-spacing: 0.1rem;
            }
        }
    }

    // @media (max-width:@big-desktop) {
    //     .counterContent {

    //         gap: 100px;
    //     }
    //     .counterInnerContent {
    //         .numberContent {
    //             span {
    //                 font-size: 5rem;
    //             }
    //         }
    //     }
    // }

    // @media (max-width:@big-desktop) {
    //     .counterContent {
    //         gap: 50px;
    //         grid-template-columns: repeat(3, 1fr);
    //     }

    //     .counterInnerContent {
    //         flex-direction: column;

    //         .numberContent {
    //             text-align: center;

    //             span {
    //                 font-size: 4rem;
    //             }
    //         }
    //     }
    // }

    @media (max-width: @1650-screen) {

        .counterContent {
            gap: 100px;
        }


        .counterInnerContent {
            gap: 1.3rem;

            .numberContent {

                span {
                    font-size: 4rem;
                }

                .conterTitle {
                    font-size: 3rem;
                }
            }
        }




    }

    @media (max-width:@desktop) {
        .counterInnerContent {
            gap: 1rem;
            flex-direction: column;

            .numberContent {
                text-align: center;

                span {
                    font-size: 4rem;
                }
            }
        }

        .counterUserImg {
            width: 70px;
            height: 70px;
        }
    }

    @media (max-width:@tablet-width) {
        .counterInnerContent {
            flex-direction: column;


            .numberContent {
                text-align: center;

                span {
                    font-size: 3rem;
                    line-height: 1.2;
                }

                .conterTitle {
                    font-size: 2rem;
                }
            }
        }

    }

    @media (max-width:@mobile) {

        .counterInnerContent {
            gap: 2rem;
        }

        .couterSection {
            padding: 20px;
        }

        .counterContent {
            display: block;
        }

        .counterUserImg {
            width: 50px;
            height: 50px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .counterInnerContent {
            flex-direction: row;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            .numberContent {
                text-align: start;

                span {
                    font-size: 3rem;
                    line-height: 1;
                }

                .conterTitle {
                    font-size: 2rem;
                }
            }
        }

    }


}

// counter section css end



// Tool Process css start

.process_section {
    margin-top: 9rem;

    @media (max-width: @mobile) {
        margin-top: 5rem;
    }
}

.process_section_inner {
    width: calc(100% - 80px);
    margin: 5rem auto;

    .interest-skill-btn {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 6rem;
    }

    @media (max-width:@tablet-width) {
        width: calc(100% - 0px);
    }

    .interest-skill-result {
        // display: flex;
        // align-items: stretch;
        // justify-content: space-between;
        // flex-wrap: wrap;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 200px;

        @media (max-width:@big-desktop) {
            gap: 100px;
        }

        @media (max-width:@desktop) {
            gap: 30px;
        }

        @media (max-width:@tablet-width) {
            grid-template-columns: repeat(2, 1fr);
        }

        // .skill-block {
        //     width: 100%;


        //     @media (max-width:@tablet-width) {
        //         border-radius: 90px 0px 90px 0px;
        //         padding: 22px 20px;
        //     }

        //     &:hover {
        //         outline: 2px solid @green_color;
        //         border-color: transparent;
        //         background-color: @green_color;
        //         color: @white_color;

        //         .skill-section-number {
        //             color: @green_color;
        //             text-shadow: 1px -1px 0px @white_color, -1px 1px 0px @white_color, -1px -1px 0px @white_color, 1px 1px 0px @white_color;
        //         }
        //     }
        // }

        .skill-section {
            position: relative;
            z-index: 1;
            color: @green_color;
            border: dashed 4px @green_color;
            border-radius: 110px 0px 110px 0px;
            padding: 32px 30px;
            box-shadow: inset 0 0 0 1px @white_color;
            outline: 2px solid @white_color;
            transition: all 0.5s ease-in-out;
            // background-color: @white_color;


            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;


            &::after {
                content: '';
                height: 50px;
                display: block;
                background-image: url("../../../assets/webimage/fluent_arrow-sprint-20-regular.svg");
                background-repeat: no-repeat;
                background-position: center center;
                width: 200px;
                position: absolute;
                top: 50%;
                left: auto;
                right: -205px;
                z-index: -1;
                transform: translateY(-50%);
                background-size: 190px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            @media (max-width:@big-desktop) {
                &::after {
                    display: block;
                    width: 100px;
                    right: -100px;
                    background-size: 200px;
                    background-position: center right;
                }
            }

            @media (max-width:@desktop) {
                &::after {
                    display: none;
                }
            }


            .skill-section-number {
                text-align: right;
                display: block;
                width: 100%;
                font-family: @secondry-font-family;
                font-size: 7rem;
                color: @white_color;
                text-shadow: 1px -1px 0px @green_color, -1px 1px 0px @green_color, -1px -1px 0px @green_color, 1px 1px 0px @green_color;
                font-weight: @font-weight-500;
                line-height: 6rem;
                transition: 0.5s ease-out all;

                .skill-title {
                    font-family: @primary-font-family;
                    font-size: 2.4rem !important;
                    // margin-bottom: 15px;
                    // padding: 0px 0 15px 0;
                    font-weight: @font-weight-500;
                    text-shadow: none;
                    text-align: left;
                    color: @green_color;
                }

                @media (max-width:@tablet-width) {
                    font-size: 5rem;

                    .skill-title {
                        font-size: 2rem;
                        padding: 0;
                    }
                }
            }



            .skill-content {
                font-size: 1.4rem;
                line-height: 1.7;

                @media (max-width:@tablet-width) {
                    margin-top: 5px;
                }
            }

            &:hover {
                outline: 2px solid @green_color;
                border-color: transparent;
                background-color: @green_color;
                color: @white_color;

                .skill-section-number {
                    color: @green_color;
                    text-shadow: 1px -1px 0px @white_color, -1px 1px 0px @white_color, -1px -1px 0px @white_color, 1px 1px 0px @white_color;
                }

                .skill-title {
                    color: @white_color;
                }
            }

            @media (max-width:@tablet-width) {

                flex: inherit;
                border-radius: 90px 0px 90px 0px;
                padding: 22px 20px;
            }
        }


        @media (max-width:@mobile) {
            display: block;

            .skill-section {
                width: 100%;
                max-width: 470px;
                margin: 20px auto 0;

                &:nth-child(2) {

                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }




    @media (max-width: @mobile) {
        width: 100%;
    }
}

// Tool Process css end

//INNOVATIVE RENEWABLE ENERGY TRAINING SOLUTIONS css -start
.energyRainingSection {
    margin-top: 8rem;
}

.enery-training {
    isolation: isolate;

    .grayBgSection {
        padding: 56px 65px;
        position: relative;

        &::before {
            position: absolute;
            content: "";
            background-color: @grey_bg_color;
            height: 100%;
            width: 130dvw;
            top: 0;
            left: 0;
            z-index: -2;
        }

    }

    .windmilGif {
        position: absolute;
        bottom: 0;
        right: -15rem;
        opacity: 0.5;
        z-index: -1;
        max-width: 600px;

        @media (max-width: @mobile) {
            display: none;
        }
    }

    h2 {
        font-family: @secondry-font-family;
        // font-size: 10rem;
        font-size: 8.5rem;
        line-height: 0.9;
        font-weight: @font-weight-400;

        span {
            color: @green_color;
            font-family: @secondry-font-family;
        }
    }

    p {
        font-size: 1.8rem;
        font-weight: @font-weight-400;
        line-height: 1.5;
    }

    @media (max-width:@big-desktop) {

        .grayBgSection {
            padding: 5rem;

            h2 {

                font-size: 5rem;
            }
        }
    }

    @media (max-width :@tablet-width) {
        .grayBgSection {
            padding: 2rem;

            h2 {

                font-size: 4rem;
            }
        }

        p {
            font-size: 1.4rem;
        }

        .windmilGif {
            width: 500px;
        }

    }

    @media (max-width: @mobile) {
        .grayBgSection {
            background-color: @grey_bg_color;

            &::before {
                display: none;
            }

            h2 {

                font-size: 3.5rem;
            }
        }

        p {

            margin-top: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: @mobile) {
        .grayBgSection {

            h2 {

                font-size: 3rem;
            }
        }
    }

}

//INNOVATIVE RENEWABLE ENERGY TRAINING SOLUTIONS css -end

//achive-section css start

.achive-section {
    margin-top: 6rem;

    .content_section {
        margin-bottom: 5rem;

        @media (max-width: @mobile) {
            margin-bottom: 3rem;
        }
    }

    .achiveImg {
        position: relative;

    }


    .achiveCardContent {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2rem;
        z-index: 1;
        background-color: @white_color;
        width: 100%;
        max-width: calc(100% - 2rem);
        min-height: 55px;

        margin: 0 auto;
        padding: 1.2rem;
        border-radius: 5px;
        transition: all 0.5s linear;
        overflow: hidden;



        @media (max-width:@min-mobile) {
            max-width: calc(100% - 2rem);
        }


        .content {
            // display: flex;
            // align-items: flex-start;
            // justify-content: flex-end;
            // flex-direction: column;
            // position: relative;
            // left: 2.2rem;
            // right: 2.2rem;
            bottom: -0.5rem;
            transition: all 0.3s linear;

            .achiveCardTitle {
                font-size: 2.9rem;
                font-family: @secondry-font-family;
                font-weight: @font-weight-400;
                // position: relative;
                // width: 85%;

                &::after {
                    content: "";
                    position: absolute;
                    width: 4rem;
                    background-color: @green_color;
                    height: 3px;
                    border-radius: 50px;
                    left: 1.5rem;
                    bottom: 10px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 1rem;
                    background-color: @green_color;
                    height: 3px;
                    border-radius: 50px;
                    left: 6rem;
                    // top: 1rem;
                    bottom: 10px;
                }

                @media (max-width:@1300-width) {
                    font-size: 2rem;
                }

                @media (max-width:@desktop) {
                    font-size: 2.2rem;
                }
            }

            .achiveCardDescription {
                opacity: 0;
                // display: none;
                width: 100%;
                height: 0;
                margin-top: 0px;
                transition: all 0.2s linear;

                p {
                    font-size: 1.6rem;
                    letter-spacing: 1px;
                    line-height: 1.6;
                    margin: 0;



                }

            }
        }




        .achiveCardNumber {
            font-family: @secondry-font-family;
            font-size: 10.4rem;
            color: @white_color;
            text-shadow: 1px -1px 0px @green_color, -1px 1px 0px @green_color, -1px -1px 0px @green_color, 1px 1px 0px @green_color;
            font-weight: @font-weight-500;
            line-height: 6rem;
            width: 50%;
            position: absolute;
            right: 2.2rem;
            bottom: 2.2rem;
            text-align: right;
            width: 24%;

            @media (max-width: @big-desktop) {
                font-size: 9rem;

            }

            @media (max-width: @tablet-width) {
                font-size: 7rem;
            }

            @media (max-width: @mobile) {
                font-size: 4rem;
            }
        }
    }

    .achive-block {
        position: relative;
        transition: all 0.5s ease-in-out;
        height: 618px;

        &::after {
            transition: all 2s ease-in;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: block;
            background: rgba(0, 0, 0, 0.30);
            height: 100%;
            z-index: 0;

        }

        &:hover {
            &::after {
                display: none;
            }

            .achiveCardContent {
                min-height: calc(100% - 30px);


                .content {
                    position: absolute;
                    bottom: 4rem;

                    @media (max-width:@mobile) {
                        bottom: 0;
                    }
                }

                .achiveCardDescription {
                    opacity: 1;
                    // display: block;
                    height: auto;
                    margin-top: 20px;

                    @media (max-width: @big-desktop) {
                        margin-top: 20px;

                    }

                }

                .achiveCardNumber {
                    bottom: auto;
                    top: 3rem;

                    @media (max-width: @big-desktop) {
                        top: 3rem;
                    }
                }

                .achiveCardTitle {
                    // width: 75%;
                    position: relative;
                    font-size: 3.1rem;

                    &::before,
                    &::after {
                        bottom: 0;
                        left: 0;
                    }

                    &::before {
                        left: 5rem;
                    }

                    @media (max-width: @desktop) {
                        width: 100%;
                    }

                    @media (max-width:@min-mobile) {
                        width: 95%;
                    }
                }
            }
        }

        .achiveImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    @media (max-width: @big-desktop) {
        .achive-block {
            margin-bottom: 2rem;
        }

    }



    @media (max-width:@1650-screen) {
        .achiveCardContent {
            .content {


                .achiveCardDescription {
                    p {

                        line-height: 1.4;
                        font-size: 1.4rem;
                    }
                }
            }
        }

    }

    @media (max-width:@desktop) {
        .achiveCardContent {
            .content {


                .achiveCardDescription {
                    p {

                        line-height: 1.6;
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    @media (max-width:@tablet-width) {
        .achive-block {
            // margin: 3rem 0;
            // height: 75dvh;


            .achiveImg {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    @media (max-width: @mobile) {

        margin-top: 5rem;

        .achiveCardContent {
            .content {
                .achiveCardTitle {
                    font-size: 2.2rem;
                }

                .achiveCardDescription {
                    p {

                        font-size: 1.6rem;
                    }
                }
            }
        }

        .achive_block {
            display: block;
        }

        .achive-block {
            margin: 3rem 0;
            // height: 50dvh;
            height: 450px;

            .achiveImg {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

    }

    @media (max-width:@min-mobile) {
        .achiveCardContent {
            .content {
                .achiveCardTitle {
                    font-size: 2.2rem;
                }

                .achiveCardDescription {
                    p {

                        font-size: 1.4rem;
                    }
                }
            }
        }

        .achive-block {
            margin: 3rem 0;
            // height: 50dvh;
            height: 500px;

            .achiveImg {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

//achive-section css end

//Community-section start
.community-section {
    margin: 9rem 0;

    .common_button {
        margin-top: 2rem;
    }

    .community-content {
        background-color: @dark_grey_bg_color;

        .achiveImg {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .communty-descreption {
            order: 0;

            @media (max-width: @mobile) {
                order: 1;
            }
        }

        &:nth-child(odd) {
            .communty-descreption {
                order: 2;

                @media (max-width: @mobile) {
                    order: 1;
                }
            }
        }
    }

    .communty-descreption {
        padding: 3rem;





        .communty-content {
            font-size: 1.6rem;
            line-height: 1.9;

            @media (max-width:@tablet-width) {
                line-height: normal;
            }

            @media (max-width: @mobile) {

                font-size: 1.3rem;

            }
        }

        @media (max-width: @mobile) {
            padding: 2rem;
        }
    }



    @media (max-width: @mobile) {
        margin-top: 5rem;
    }
}

//Community-section end

//training_solutions css start
.training_solutions {
    padding: 9rem 0;
    margin: 0;
    background-image: url("../../../assets/webimage/section2-home1.jpg");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;

    .content_section {
        margin-bottom: 5rem;

        @media (max-width: @mobile) {
            margin-bottom: 3rem;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .training_solutions_heading {
        font-family: @secondry-font-family;
        font-size: 7rem;
        font-weight: @font-weight-400;

        @media (max-width: @tablet-width) {
            font-size: 4.9rem;
        }

    }

    .box {
        width: 100%;
        max-width: 1115px;
        margin: 0 auto;
        text-align: center;

        position: relative;
        overflow: hidden;


        .mainBox {
            // margin: -1px;
            padding: 2rem;
            border: 1px solid transparent;
            position: relative;




            &:nth-child(1) {
                border-image: linear-gradient(35deg,
                        rgba(255, 255, 255, 1) 0%,
                        @green_color 60%,
                        rgba(0, 143, 104, 0) 105%) 1;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                margin: 0 -1px 0 0;
            }


            &:nth-child(2) {
                border-image: linear-gradient(180deg,
                        rgba(255, 255, 255, 0) 0%,
                        @green_color 60%,
                        @green_color 105%) 1;
                border-top: 0;
                margin: 0 -1px 0 0;

                @media (max-width:@tablet-width) {
                    border-right: 0;
                }
            }


            &:nth-child(3) {
                border-image: linear-gradient(165deg,
                        rgba(255, 255, 255, 0) 0%,
                        @green_color 60%,
                        rgba(0, 143, 104, 0) 105%) 1;
                border-top: 0;
                border-right: 0;
                margin: 0 -1px 0 0;

                @media (max-width:@tablet-width) {
                    border-image: linear-gradient(250deg, rgba(255, 255, 255, 0) 0%, #1DAB3B 60%, rgba(0, 143, 104, 0) 105%) 1;
                    border-left: 0;
                    border-right: 10px;
                }

            }

            &:nth-child(4) {
                margin: 0 -1px 0 0;

                @media (max-width:@tablet-width) {
                    border-image: linear-gradient(245deg,
                            rgba(255, 255, 255, 0) 0%,
                            @green_color 60%,
                            @green_color 105%) 1;
                    border-top: 0;

                    border-right: 0;
                }
            }



            &:nth-child(5) {
                border-image: linear-gradient(0deg,
                        rgba(255, 255, 255, 0) 0%,
                        @green_color 60%,
                        @green_color 105%) 1;
                border-top: 0;
                margin: 0 -1px 0 0;

                @media (max-width:@tablet-width) {
                    border-left: 0;
                }

            }

            img {
                width: auto;
                height: 5rem;
                transition: all 0.3s ease-in-out;

                @media (max-width:@mobile) {
                    height: 3rem;
                }
            }

            .subSections {
                border: 1px solid transparent;
                transition: all 0.5s ease-in-out;
                min-height: 290px;
                padding: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: rgba(255, 255, 255, 0.6);

                @media (max-width:@mobile) {
                    min-height: 250px;
                }
            }

            .training_solutions_box_heading {
                font-size: 2rem;
                font-weight: @font-weight-700;
                font-family: @primary-font-family;
                // margin-bottom: 2rem;
                margin: 1rem 0;
            }

            &:hover {
                a {
                    color: @black_color;
                }

                img {
                    height: 6rem;
                    filter: invert(55%) sepia(60%) saturate(694%) hue-rotate(82deg) brightness(84%) contrast(83%);

                    @media (max-width:@mobile) {
                        height: 4rem;
                    }
                }

                .subSections {
                    border: 1px solid @green_color;
                    box-shadow: 0 0 8px rgba(@green_color, 20%);
                }

                .training_solutions_box_heading {
                    color: @green_color;
                }
            }


            @media (max-width:@mobile) {
                padding: 0;
                border: none !important;

                .subSections {
                    border-width: 1px;
                    border-style: solid;
                    border-image: linear-gradient(0deg,
                            rgba(29, 171, 59, 1) 0%,
                            @green_color 60%,
                            @green_color 105%) 1;
                    margin-bottom: 15px;
                }
            }
        }




        .training_solutions_box_discription {
            font-family: @primary-font-family;
            font-weight: @font-weight-400;
            font-size: 1.6rem;
            line-height: 1.6;

            @media (max-width:@mobile) {
                margin: 0;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            height: 6px;
            background-color: @white_color;
            display: block;
            left: 0;
            right: 0;
            z-index: 1;
        }
    }

    // @media (max-width:@tablet-width) {
    //     font-size: 6rem;
    // }

    // @media (max-width:@tablet-width) {
    //     font-size: 4rem;
    // }

    @media (max-width:@mobile) {
        padding: 3rem 0;

        .training_solutions_heading {

            font-size: 3.5rem;
        }


    }

    @media (max-width:@min-mobile) {
        padding: 0rem 0;

        .training_solutions_heading {

            font-size: 3rem;
        }
    }
}

// training_solutions_heading end